import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import App from 'components';
import Home from './Home';
import Contact from './Contact';
import PreWedding from './PreWedding';
import Itinerary from './Itinerary';
import Gift from './Gift';
import Weather from './Weather';

const RoutedApp = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />}>
        <Route exact path="home" element={<Home />} />
        <Route exact path="boda" element={<Itinerary />} />
        <Route exact path="preboda" element={<PreWedding />} />
        <Route exact path="tiempo" element={<Weather />} />
        <Route exact path="regalo" element={<Gift />} />
        <Route exact path="contacto" element={<Contact />} />
        {/* Home by default */}
        <Route path="" element={<Navigate to="/home" />} />
        <Route path="*" element={<Navigate to="/home" />} />
      </Route>
    </Routes>
  </BrowserRouter>
);

export default RoutedApp;
