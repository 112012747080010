export const pages = [
  {
    id: 1,
    to: 'home',
    name: 'Home'
  },
  {
    id: 2,
    to: 'preboda',
    name: 'Preboda'
  },
  {
    id: 3,
    to: 'boda',
    name: 'Boda'
  },
  {
    id: 4,
    to: 'tiempo',
    name: 'Tiempo'
  },
  {
    id: 5,
    to: 'regalo',
    name: 'Regalo'
  },
  {
    id: 6,
    to: 'contacto',
    name: 'Contacto'
  }
];

export const wedding = {
  coupleNames: 'Flor & Fer',
  coupleInitials: 'F & F',
  dateWritten: '10 | 08 | 2024',
  date: '10.08.2024',
  location: 'Monasterio del Espino (Burgos)'
};

export const home = {
  text: `Querida familia y amigos,\
  
  Estamos contando los días para juntarnos con todos vosotros en el que será, sin duda alguna, el día más feliz e importante de nuestra vida en común.\
  
  Hemos preparado esta web para que tengáis a mano toda la información de la boda. No obstante, si se nos escapa algo no dudéis en [escribirnos](/contacto), trataremos de ayudaros con cualquier duda que os surja.\
  
  Hemos puesto toda nuestra dedicación para que disfrutéis del día de principio a fin y esperamos que así sea.\
  
  ¡Os esperamos!`
};

export const locationIds = {
  TOUR_MEETING_POINT: 'tour_meeting_point',
  PREWEDDING: 'prewedding',
  WEDDING: 'wedding',
  BURGOS_BUS_STOP: 'burgos_bus_stop',
  MIRANDA_BUS_STOP_1: 'miranda_bus_stop_1',
  MIRANDA_BUS_STOP_2: 'miranda_bus_stop_2'
};
export const places = {
  [locationIds.TOUR_MEETING_POINT]: {
    lat: 42.3402413,
    lng: -3.7057617,
    placeId: 'ChIJ8wBWTCn9RQ0RXYk9KfsB6wY'
  },
  [locationIds.PREWEDDING]: {
    lat: 42.3416484,
    lng: -3.7094667,
    placeId: 'ChIJHWQGICn9RQ0Rd1pdj6lE3Hw'
  },
  [locationIds.WEDDING]: {
    lat: 42.7272218,
    lng: -3.0500716,
    placeId: 'ChIJ7ysnvqebTw0RLBPESUVONuU'
  },
  [locationIds.BURGOS_BUS_STOP]: {
    lat: 42.3440682,
    lng: -3.6977004,
    placeId: 'ChIJQSCFVNf8RQ0RsaTTz382VK4'
  },
  [locationIds.MIRANDA_BUS_STOP_1]: {
    lat: 42.6903988,
    lng: -2.9402436,
    placeId: 'ChIJ4THroxmbTw0RZH6UdZ6ZK3A'
  },
  [locationIds.MIRANDA_BUS_STOP_2]: {
    lat: 42.6886111,
    lng: -2.9536111,
    placeId: 'ChIJD-uhPb6aTw0Ro6hUwuTMZC0'
  }
};

export const itinerary = {
  burgosBus: `El autobús de **Burgos** saldrá puntual a las **11.30 de Plaza España**. Por favor, os rogamos que estéis en el punto de recogida **15 min antes de la hora de salida** para evitar retrasos.`,
  mirandaBus: `El autobús de **Miranda de Ebro** saldrá puntual de la **estación de autobuses a las 12.15** y hará una parada en el **Hotel Tudanca a las 12.20** antes de salir camino del Monasterio del Espino. De igual forma, os rogamos que estéis en el punto de recogida **15 min antes de la hora de salida** para evitar retrasos.`,
  ceremony:
    'La **ceremonia** tendrá lugar a las **13.00 en el Monasterio del Espino**, situado en la población de Santa Gadea del Cid (Burgos). Aquí os dejamos el enlace a Google Maps para que sea fácil de localizar para aquellos que decidáis venir por vuestra cuenta.',
  celebration: `La fiesta continuará después de la ceremonia. Justo después, daremos paso a un **cóctel** en los alrededores del monasterio. Le seguirá el **banquete**, la posterior **celebración** y algunas sorpresas que nos reservamos para el gran día :)\
  
  Pasaremos todo el día juntos en el mismo recinto y posteriormente habrá **autobuses de vuelta** tanto a Miranda de Ebro como a Burgos **a las 01.30** para aquellos que lo hayáis solicitado.`,
  burgosContacts: [
    {
      id: 1,
      name: 'Nada (Responsable autobús Burgos)',
      phone: '666 66 97 70'
    }
  ],
  mirandaContacts: [
    {
      id: 1,
      name: 'Jenny (Responsable autobús Miranda)',
      phone: '618 94 91 92'
    }
  ]
};

export const preWedding = {
  textStart:
    'Todos aquellos que queráis, estáis invitados a acompañarnos el **viernes 9 de Agosto** para ir aclimatándonos de cara al gran día.',
  textTour:
    'Comenzaremos con una **visita guiada al casco histórico de Burgos** a las **18.30**. El punto de encuentro se encuentra en la **estatua del peregrino** situada en la **Plaza del Rey San Fernando** junto a la **Catedral**. Procurad llegar 10 minutos antes porque saldremos puntuales a la hora indicada.',
  textPreWedding:
    'Más tarde, daremos paso a la **preboda** que se celebrará en **La Ciudadela**, situada junto al **Mirador del Castillo**, a las **20.00**. Para aquellos que hagáis la visita guiada, os dejarán a los pies de La Ciudadela y podréis llegar a pie fácilmente nada más acabar. Los demás que decidáis venir, nos vemos allí directamente.',
  textEnd: `Será el momento ideal para los ansiados reencuentros y presentaciones previas a la boda y para disfrutar de una de las ciudades con más encanto de nuestro país\
    
    ¡No os lo podéis perder!`
};

export const weather = {
  warning: `Si los burgaleses nos autodenominamos hijos del frío, es por algo. Por ello y a pesar de celebrar nuestra boda en Agosto, **os recomendamos que llevéis una chaqueta para no pasar frío por las noches**.`,
  intro:
    'Si tenéis dudas, echad un vistazo a la previsión del tiempo para los próximos días.'
};

export const gift = {
  text: `Contar con vuestra presencia en un día tan especial para nosotros es, sin duda alguna, el mejor de los regalos que podríamos recibir.\
  
  No obstante, si queréis tener un detalle con nosotros, ¡sumad kilómetros a nuestro viaje!`,
  iban: 'ES52 1563 2626 3432 6644 8301'
};

export const contact = {
  text: 'Gracias por formar parte de nuestro día. Si tienes cualquier pregunta, no dudes en contactar con nosotros.',
  contacts: [
    {
      id: 1,
      name: 'Flor',
      phone: '667 83 73 02',
      email: 'fnieblac@gmail.com'
    },
    {
      id: 2,
      name: 'Fernando',
      phone: '620 27 26 44',
      email: 'fernanalegria@gmail.com'
    }
  ]
};
