import { pages } from 'assets/js/data';
import { useContext } from 'react';
import { ActivePageContext } from 'state/activePage';
import { wedding } from 'assets/js/data'

const PageTitle = () => {
  const [state] = useContext(ActivePageContext);

  return (
    <div className="css-2z2po7">
      <h2 className="css-1iffxqw">
        {state.activePage === 'home'
          ? wedding.coupleInitials
          : pages.find(({ to }) => to === state.activePage).name}
      </h2>
    </div>
  );
};

export default PageTitle;
