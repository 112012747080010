import './App.css';
import ErrorBoundary from './ErrorBoundary';
import Container from './Container';
import Main from './Main';
import Footer from './Footer';
import Background from './Background';

const App = () => (
  <ErrorBoundary>
    <Container>
      <Background />
      <Main />
      <Footer />
    </Container>
  </ErrorBoundary>
);

export default App;
