import RouteWrapper from 'routes/RouteWrapper';
import { GoogleMapsLink, Image, Markdown } from 'common';
import { itinerary, locationIds } from 'assets/js/data';
import weddingImages from 'assets/img/weddingImages';
import { ImgResIndex } from 'utils/image';
import ContactInfo from './Contact/ContactInfo';

const Itinerary = () => (
  <RouteWrapper>
    <div className="css-iex2id">
      <Image
        highResSrc={weddingImages[ImgResIndex.LOW]}
        mediumResSrc={weddingImages[ImgResIndex.MEDIUM]}
        lowResSrc={weddingImages[ImgResIndex.LOW]}
      />
      <Markdown>{itinerary.burgosBus}</Markdown>
      <GoogleMapsLink locationId={locationIds.BURGOS_BUS_STOP} text="Parada Burgos (11.30)" />
      <ContactInfo contacts={itinerary.burgosContacts} />
      <Markdown>{itinerary.mirandaBus}</Markdown>
      <GoogleMapsLink locationId={locationIds.MIRANDA_BUS_STOP_1} text="Parada 1 Miranda (12.15)" />
      <GoogleMapsLink locationId={locationIds.MIRANDA_BUS_STOP_2} text="Parada 2 Miranda (12.20)" />
      <ContactInfo contacts={itinerary.mirandaContacts} />
      <Markdown>{itinerary.ceremony}</Markdown>
      <GoogleMapsLink locationId={locationIds.WEDDING} text="Monasterio del Espino (13.00)" />
      <Markdown>{itinerary.celebration}</Markdown>
    </div>
  </RouteWrapper>
);

export default Itinerary;
