import { ActivePageProvider } from 'state/activePage';
import { WindowDimensionsProvider } from 'context/windowDimensions';

const Providers = ({ children }) => (
  <ActivePageProvider>
    <WindowDimensionsProvider>{children}</WindowDimensionsProvider>
  </ActivePageProvider>
);

export default Providers;
