import coupleImages from 'assets/img/coupleImages';
import { useState, useEffect, useRef } from 'react';
import { ImgResIndex } from 'utils/image';

const ImageHome = () => {
  const [photoId, setPhotoId] = useState(0);
  const [imageHeight, setImageHeight] = useState(null);
  const nodeRef = useRef(null);
  const photo = coupleImages[photoId];

  const onImageLoad = ({ target: img }) => {
    setImageHeight(img.offsetHeight);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      const newPhotoId = photoId + 1 >= coupleImages.length ? 0 : photoId + 1;
      setPhotoId(newPhotoId);
    }, 4000);

    return () => {
      clearInterval(intervalId);
    };
  }, [photoId]);

  return (
    <div className="PhotoItem css-mx8zre" data-testid="photo-item-80496675">
      <div
        className="picture-wrapper"
        style={
          imageHeight && {
            height: imageHeight
          }
        }
      >
        <picture ref={nodeRef}>
          <source
            media="(min-resolution: 192dpi) and (min-width: 1280px),(-webkit-min-device-pixel-ratio: 2) and (min-width: 1280px)"
            srcSet={photo[ImgResIndex.HIGH]}
          />
          <source
            media="(min-resolution: 192dpi) and (min-width: 1024px),(-webkit-min-device-pixel-ratio: 2) and (min-width: 1024px)"
            srcSet={photo[ImgResIndex.HIGH]}
          />
          <source
            media="(min-resolution: 192dpi) and (min-width: 768px),(-webkit-min-device-pixel-ratio: 2) and (min-width: 768px)"
            srcSet={photo[ImgResIndex.HIGH]}
          />
          <source
            media="(min-resolution: 192dpi), (-webkit-min-device-pixel-ratio: 2)"
            srcSet={photo[ImgResIndex.HIGH]}
          />
          <source
            media="(min-resolution: 144dpi) and (min-width: 1280px),(-webkit-min-device-pixel-ratio: 1.5) and (min-width: 1280px)"
            srcSet={photo[ImgResIndex.MEDIUM]}
          />
          <source
            media="(min-resolution: 144dpi) and (min-width: 1024px),(-webkit-min-device-pixel-ratio: 1.5) and (min-width: 1024px)"
            srcSet={photo[ImgResIndex.MEDIUM]}
          />
          <source
            media="(min-resolution: 144dpi) and (min-width: 768px),(-webkit-min-device-pixel-ratio: 1.5) and (min-width: 768px)"
            srcSet={photo[ImgResIndex.MEDIUM]}
          />
          <source
            media="(min-resolution: 144dpi), (-webkit-min-device-pixel-ratio: 1.5)"
            srcSet={photo[ImgResIndex.MEDIUM]}
          />
          <source media="(min-width: 1280px)" srcSet={photo[ImgResIndex.LOW]} />
          <source media="(min-width: 1024px)" srcSet={photo[ImgResIndex.LOW]} />
          <source media="(min-width: 768px)" srcSet={photo[ImgResIndex.LOW]} />
          <source srcSet={photo[ImgResIndex.LOW]} />
          <img
            loading="eager"
            alt=""
            src={photo[ImgResIndex.LOW]}
            className="css-1ago99h"
            onLoad={onImageLoad}
          />
        </picture>
      </div>
    </div>
  );
};

export default ImageHome;
