import flowers1200 from 'assets/img/mobile/texture_1200.png';
import flowers900 from 'assets/img/mobile/texture_900.png';
import flowers600 from 'assets/img/mobile/texture_600.png';

const TopImageMobile = () => (
  <picture className="css-1swc5nz">
    <source
      media="(min-resolution: 192dpi), (-webkit-min-device-pixel-ratio: 2)"
      srcSet={flowers1200}
    />
    <source
      media="(min-resolution: 144dpi), (-webkit-min-device-pixel-ratio: 1.5)"
      srcSet={flowers900}
    />
    <source srcSet={flowers600} />
    <img loading="eager" alt="" src={flowers600} className="css-1ago99h" />
  </picture>
);

export default TopImageMobile;
