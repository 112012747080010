import RouteWrapper from 'routes/RouteWrapper';
import { Image, Markdown } from 'common';
import { gift } from 'assets/js/data';
import BankAccount from './BankAccount';
import honeymoonImages from 'assets/img/honeymoonImages';
import { ImgResIndex } from 'utils/image';

const Gift = () => (
  <RouteWrapper>
    <div className="css-iex2id">
      <Image
        highResSrc={honeymoonImages[ImgResIndex.LOW]}
        mediumResSrc={honeymoonImages[ImgResIndex.MEDIUM]}
        lowResSrc={honeymoonImages[ImgResIndex.LOW]}
      />
      <Markdown>{gift.text}</Markdown>
      <div className="ParagraphItem css-huenw5">
        <BankAccount />
      </div>
    </div>
  </RouteWrapper>
);

export default Gift;
