import * as types from './types';

const reducer = (_state, action) => {
  switch (action.type) {
    case types.SET_ACTIVE_PAGE:
      return {
        activePage: action.payload
      };
    default:
      throw new Error('Invalid action');
  }
};

export default reducer;
