import ReactWeather, { useOpenWeather } from 'routes/Weather/openWeather';

const customStyles = {
  fontFamily: 'Minion Pro',
  gradientStart: '#336733',
  gradientMid: '#419141',
  gradientEnd: '#527952',
  locationFontColor: '#FFF',
  todayTempFontColor: '#FFF',
  todayDateFontColor: '#FFF',
  todayRangeFontColor: '#FFF',
  todayDescFontColor: '#FFF',
  todayInfoFontColor: '#FFF',
  todayIconColor: '#FFF',
  forecastBackgroundColor: '#FFF',
  forecastSeparatorColor: '#DDD',
  forecastDateColor: '#777',
  forecastDescColor: '#777',
  forecastRangeColor: '#777',
  forecastIconColor: '#527952'
};

const OpenWeatherWrapper = ({ lat, lng, locationLabel }) => {
  const { data, isLoading, errorMessage } = useOpenWeather({
    key: process.env.REACT_APP_OPEN_WEATHER_API_KEY,
    lat,
    lon: lng,
    lang: 'es',
    unit: 'metric' // values are (metric, standard, imperial)
  });
  return (
    <div className="CardItem css-mx8zre">
      <ReactWeather
        theme={customStyles}
        isLoading={isLoading}
        errorMessage={errorMessage}
        data={data}
        lang="es"
        locationLabel={locationLabel}
        unitsLabels={{ temperature: 'C', windSpeed: 'Km/h' }}
        showForecast
      />
    </div>
  );
};

export default OpenWeatherWrapper;
