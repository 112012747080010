import { home } from 'assets/js/data';
import { Markdown } from 'common';


const TextHome = () => (
    <Markdown>
        {home.text}
    </Markdown>
);

export default TextHome;
