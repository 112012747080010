import { getMapsSearchUrl } from 'api/googleMaps';
import ButtonLink from './ButtonLink';

const GoogleMapsLink = ({ locationId, text }) => (
  <div className="ActivityItem css-o6iq6k" data-testid="activity-item-82989966">
    <div className="css-10u2vbj">
      <ButtonLink href={getMapsSearchUrl(locationId)} text={text ?? "Ir a Google Maps"} />
    </div>
  </div>
);

export default GoogleMapsLink;
