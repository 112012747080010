const HamburgerMenuButton = ({
  isHamburgerMenuOpen,
  toggleIsHamburgerMenuOpen
}) => {
  const handleOnClick = () => {
    toggleIsHamburgerMenuOpen();
  };

  return (
    <div className="css-1rf9dig">
      <button
        className="css-bcsx0a"
        onClick={handleOnClick}
      >
        <span
          className={isHamburgerMenuOpen ? 'css-5d390f' : 'css-17h8xdu'}
          open={isHamburgerMenuOpen}
        ></span>
        <span className="css-1wkn33">Menu Button</span>
      </button>
    </div>
  );
};

export default HamburgerMenuButton;
