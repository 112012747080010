import prewedding1020 from 'assets/img/prewedding_1020.png';
import prewedding765 from 'assets/img/prewedding_765.png';
import prewedding510 from 'assets/img/prewedding_510.png';
import { ImgResIndex } from 'utils/image';

const preweddingImages = {
  [ImgResIndex.HIGH]: prewedding1020,
  [ImgResIndex.MEDIUM]: prewedding765,
  [ImgResIndex.LOW]: prewedding510
};

export default preweddingImages;
