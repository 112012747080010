import { Component } from 'react'; 

class ErrorBoundary extends Component {
  state = { hasError: false };

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Ups! Ha habido un error. Contacte con el administrador (fernanalegria@gmail.com) para encontrar una solución.</h1>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
