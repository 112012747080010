import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { ActivePageContext, activePageActions } from 'state/activePage';

const BottomNavBarMobileLink = ({ to, name }) => {
    const [state, dispatch] = useContext(ActivePageContext);
    const navigate = useNavigate();

  const handleOnClick = e => {
    e.preventDefault();
    dispatch(activePageActions.setActivePage(to));
    navigate(`/${to}`);
  };

  return (
    <li className="css-1u1vvf2">
      <a href={to} className="css-12he7qw" onClick={handleOnClick}>
        <span className={to === state.activePage ? 'css-1b16ice' : 'css-1d1dv5n'}>
          {name}
        </span>
      </a>
    </li>
  );
};

export default BottomNavBarMobileLink;
