import { pages } from 'assets/js/data';
import BottomNavBarMobileLink from './BottomNavBarMobileLink';

const BottomNavBarMobile = () => (
  <div className="css-iex2id">
    <div className="css-1f79j9i">
      <ul className="css-19g66ca">
        {pages.map(({ id, to, name }) => (
          <BottomNavBarMobileLink key={id} to={to} name={name} />
        ))}
      </ul>
    </div>
  </div>
);

export default BottomNavBarMobile;
