import { createContext } from 'react';

export const WindowDimensionsContext = createContext();

export const WindowDimensionsProvider = ({ children }) => {
  const { innerWidth: width, innerHeight: height } = window;
  return (
    <WindowDimensionsContext.Provider value={{ width, height }}>
      {children}
    </WindowDimensionsContext.Provider>
  );
};
