import coupleN1R1020 from 'assets/img/couple_1_1020.png';
import coupleN1R765 from 'assets/img/couple_1_765.png';
import coupleN1R510 from 'assets/img/couple_1_510.png';
import coupleN2R1020 from 'assets/img/couple_2_1020.png';
import coupleN2R765 from 'assets/img/couple_2_765.png';
import coupleN2R510 from 'assets/img/couple_2_510.png';
import coupleN3R1020 from 'assets/img/couple_3_1020.png';
import coupleN3R765 from 'assets/img/couple_3_765.png';
import coupleN3R510 from 'assets/img/couple_3_510.png';
import coupleN4R1020 from 'assets/img/couple_4_1020.png';
import coupleN4R765 from 'assets/img/couple_4_765.png';
import coupleN4R510 from 'assets/img/couple_4_510.png';
import coupleN5R1020 from 'assets/img/couple_5_1020.png';
import coupleN5R765 from 'assets/img/couple_5_765.png';
import coupleN5R510 from 'assets/img/couple_5_510.png';
import coupleN6R1020 from 'assets/img/couple_6_1020.png';
import coupleN6R765 from 'assets/img/couple_6_765.png';
import coupleN6R510 from 'assets/img/couple_6_510.png';
import coupleN7R1020 from 'assets/img/couple_7_1020.png';
import coupleN7R765 from 'assets/img/couple_7_765.png';
import coupleN7R510 from 'assets/img/couple_7_510.png';
import coupleN8R1020 from 'assets/img/couple_8_1020.png';
import coupleN8R765 from 'assets/img/couple_8_765.png';
import coupleN8R510 from 'assets/img/couple_8_510.png';
import coupleN9R1020 from 'assets/img/couple_9_1020.png';
import coupleN9R765 from 'assets/img/couple_9_765.png';
import coupleN9R510 from 'assets/img/couple_9_510.png';
import coupleN10R1020 from 'assets/img/couple_10_1020.png';
import coupleN10R765 from 'assets/img/couple_10_765.png';
import coupleN10R510 from 'assets/img/couple_10_510.png';
import coupleN11R1020 from 'assets/img/couple_11_1020.png';
import coupleN11R765 from 'assets/img/couple_11_765.png';
import coupleN11R510 from 'assets/img/couple_11_510.png';
import coupleN12R1020 from 'assets/img/couple_12_1020.png';
import coupleN12R765 from 'assets/img/couple_12_765.png';
import coupleN12R510 from 'assets/img/couple_12_510.png';
import coupleN13R1020 from 'assets/img/couple_13_1020.png';
import coupleN13R765 from 'assets/img/couple_13_765.png';
import coupleN13R510 from 'assets/img/couple_13_510.png';
import coupleN14R1020 from 'assets/img/couple_14_1020.png';
import coupleN14R765 from 'assets/img/couple_14_765.png';
import coupleN14R510 from 'assets/img/couple_14_510.png';
import coupleN15R1020 from 'assets/img/couple_15_1020.png';
import coupleN15R765 from 'assets/img/couple_15_765.png';
import coupleN15R510 from 'assets/img/couple_15_510.png';
import coupleN16R1020 from 'assets/img/couple_16_1020.png';
import coupleN16R765 from 'assets/img/couple_16_765.png';
import coupleN16R510 from 'assets/img/couple_16_510.png';
import { ImgResIndex } from 'utils/image';

const coupleImages = [
  {
    [ImgResIndex.HIGH]: coupleN1R1020,
    [ImgResIndex.MEDIUM]: coupleN1R765,
    [ImgResIndex.LOW]: coupleN1R510
  },
  {
    [ImgResIndex.HIGH]: coupleN2R1020,
    [ImgResIndex.MEDIUM]: coupleN2R765,
    [ImgResIndex.LOW]: coupleN2R510
  },
  {
    [ImgResIndex.HIGH]: coupleN3R1020,
    [ImgResIndex.MEDIUM]: coupleN3R765,
    [ImgResIndex.LOW]: coupleN3R510
  },
  {
    [ImgResIndex.HIGH]: coupleN4R1020,
    [ImgResIndex.MEDIUM]: coupleN4R765,
    [ImgResIndex.LOW]: coupleN4R510
  },
  {
    [ImgResIndex.HIGH]: coupleN5R1020,
    [ImgResIndex.MEDIUM]: coupleN5R765,
    [ImgResIndex.LOW]: coupleN5R510
  },
  {
    [ImgResIndex.HIGH]: coupleN13R1020,
    [ImgResIndex.MEDIUM]: coupleN13R765,
    [ImgResIndex.LOW]: coupleN13R510
  },
  {
    [ImgResIndex.HIGH]: coupleN7R1020,
    [ImgResIndex.MEDIUM]: coupleN7R765,
    [ImgResIndex.LOW]: coupleN7R510
  },
  {
    [ImgResIndex.HIGH]: coupleN8R1020,
    [ImgResIndex.MEDIUM]: coupleN8R765,
    [ImgResIndex.LOW]: coupleN8R510
  },
  {
    [ImgResIndex.HIGH]: coupleN9R1020,
    [ImgResIndex.MEDIUM]: coupleN9R765,
    [ImgResIndex.LOW]: coupleN9R510
  },
  {
    [ImgResIndex.HIGH]: coupleN10R1020,
    [ImgResIndex.MEDIUM]: coupleN10R765,
    [ImgResIndex.LOW]: coupleN10R510
  },
  {
    [ImgResIndex.HIGH]: coupleN14R1020,
    [ImgResIndex.MEDIUM]: coupleN14R765,
    [ImgResIndex.LOW]: coupleN14R510
  },
  {
    [ImgResIndex.HIGH]: coupleN15R1020,
    [ImgResIndex.MEDIUM]: coupleN15R765,
    [ImgResIndex.LOW]: coupleN15R510
  },
  {
    [ImgResIndex.HIGH]: coupleN11R1020,
    [ImgResIndex.MEDIUM]: coupleN11R765,
    [ImgResIndex.LOW]: coupleN11R510
  },
  {
    [ImgResIndex.HIGH]: coupleN12R1020,
    [ImgResIndex.MEDIUM]: coupleN12R765,
    [ImgResIndex.LOW]: coupleN12R510
  },
  {
    [ImgResIndex.HIGH]: coupleN16R1020,
    [ImgResIndex.MEDIUM]: coupleN16R765,
    [ImgResIndex.LOW]: coupleN16R510
  },
  {
    [ImgResIndex.HIGH]: coupleN6R1020,
    [ImgResIndex.MEDIUM]: coupleN6R765,
    [ImgResIndex.LOW]: coupleN6R510
  }
];

export default coupleImages;
