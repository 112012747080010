import moment from 'moment';

export const getTimeToWedding = () => {
  const currentTime = moment();
  const weddingTime = moment('2024-08-10T13:00:00');
  const timeToWedding = moment.duration(weddingTime.diff(currentTime));

  if (timeToWedding.asMilliseconds() < 0) {
    return {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0
    };
  }

  return {
    days: Math.floor(timeToWedding.asDays()),
    hours: timeToWedding.hours(),
    minutes: timeToWedding.minutes(),
    seconds: timeToWedding.seconds()
  };
};
