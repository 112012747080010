import { useState, useRef } from 'react';
import { Outlet } from 'react-router-dom';
import { pages } from 'assets/js/data';
import NavBarDesktop from 'common/NavBarDesktop';
import HamburgerMenuButton from './HamburgerMenuButton';
import HeaderDesktop from './HeaderDesktop';
import HeaderMobile from './HeaderMobile';
import NavBarMobile from './NavBarMobile';
import PageTitle from './PageTitle';
import PageTitleBackground from './PageTitleBackground';
import TopImageDesktop from './TopImageDesktop';
import TopImageMobile from './TopImageMobile';

const Main = () => {
  const [isHamburgerMenuOpen, setIsHamburgerMenuOpen] = useState(false);
  const topRef = useRef(null);

  const toggleIsHamburgerMenuOpen = () => {
    setIsHamburgerMenuOpen(!isHamburgerMenuOpen);
  };

  return (
    <div className="css-1abgt8j">
      <PageTitleBackground nodeRef={topRef} />
      <TopImageMobile />
      <HeaderMobile />
      <TopImageDesktop />
      <HeaderDesktop />
      <HamburgerMenuButton
        isHamburgerMenuOpen={isHamburgerMenuOpen}
        toggleIsHamburgerMenuOpen={toggleIsHamburgerMenuOpen}
      />
      <PageTitle />
      <NavBarMobile
        isHamburgerMenuOpen={isHamburgerMenuOpen}
        toggleIsHamburgerMenuOpen={toggleIsHamburgerMenuOpen}
      />
      <NavBarDesktop pages={pages} className="p-t-20" />
      <Outlet context={topRef} />
    </div>
  );
};

export default Main;
