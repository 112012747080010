import RouteWrapper from 'routes/RouteWrapper';
import ContactInfo from './ContactInfo';
import TextContact from './TextContact';
import { contact } from 'assets/js/data';

const Contact = () => (
  <RouteWrapper>
    <div className="css-iex2id">
      <TextContact />
      <div className="horizontal-break css-huenw5" />
      <ContactInfo contacts={contact.contacts} />
    </div>
  </RouteWrapper>
);

export default Contact;
