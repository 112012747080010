const Image = ({ highResSrc, mediumResSrc, lowResSrc }) => (
  <div className="ImageItem css-mx8zre">
    <picture>
      <source
        media="(min-resolution: 192dpi) and (min-width: 1280px),(-webkit-min-device-pixel-ratio: 2) and (min-width: 1280px)"
        srcSet={highResSrc}
      />
      <source
        media="(min-resolution: 192dpi) and (min-width: 1024px),(-webkit-min-device-pixel-ratio: 2) and (min-width: 1024px)"
        srcSet={highResSrc}
      />
      <source
        media="(min-resolution: 192dpi) and (min-width: 768px),(-webkit-min-device-pixel-ratio: 2) and (min-width: 768px)"
        srcSet={highResSrc}
      />
      <source
        media="(min-resolution: 192dpi), (-webkit-min-device-pixel-ratio: 2)"
        srcSet={highResSrc}
      />
      <source
        media="(min-resolution: 144dpi) and (min-width: 1280px),(-webkit-min-device-pixel-ratio: 1.5) and (min-width: 1280px)"
        srcSet={mediumResSrc}
      />
      <source
        media="(min-resolution: 144dpi) and (min-width: 1024px),(-webkit-min-device-pixel-ratio: 1.5) and (min-width: 1024px)"
        srcSet={mediumResSrc}
      />
      <source
        media="(min-resolution: 144dpi) and (min-width: 768px),(-webkit-min-device-pixel-ratio: 1.5) and (min-width: 768px)"
        srcSet={mediumResSrc}
      />
      <source
        media="(min-resolution: 144dpi), (-webkit-min-device-pixel-ratio: 1.5)"
        srcSet={mediumResSrc}
      />
      <source media="(min-width: 1280px)" srcSet={lowResSrc} />
      <source media="(min-width: 1024px)" srcSet={lowResSrc} />
      <source media="(min-width: 768px)" srcSet={lowResSrc} />
      <source srcSet={lowResSrc} />
      <img loading="eager" alt="" src={lowResSrc} className="css-1ago99h" />
    </picture>
  </div>
);

export default Image;
