import { pages } from 'assets/js/data';
import NavBarMobileLink from './NavBarMobileLink';

const NavBarMobile = ({ isHamburgerMenuOpen, toggleIsHamburgerMenuOpen }) => (
  <nav
    role="navigation"
    className={isHamburgerMenuOpen ? 'css-10fevb3' : 'css-1htxqsx'}
  >
    <div className="css-nfosgx">
      <ul className="css-kxffhl">
        {pages.map(({ id, to, name }) => (
          <NavBarMobileLink
            key={id}
            to={to}
            name={name}
            toggleIsHamburgerMenuOpen={toggleIsHamburgerMenuOpen}
          />
        ))}
      </ul>
    </div>
  </nav>
);

export default NavBarMobile;
