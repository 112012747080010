import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { ActivePageContext, activePageActions } from 'state/activePage';

const NavBarMobileLink = ({ to, name, toggleIsHamburgerMenuOpen }) => {
  const [state, dispatch] = useContext(ActivePageContext);
  const navigate = useNavigate();

  const handleOnClick = e => {
    e.preventDefault();
    dispatch(activePageActions.setActivePage(to));
    navigate(`/${to}`);
    toggleIsHamburgerMenuOpen();
  };

  return (
    <li className="css-l698wh">
      <a href={to} className="css-12he7qw" onClick={handleOnClick}>
        <span
          className={to === state.activePage ? 'css-1b16ice' : 'css-1d1dv5n'}
        >
          {name}
        </span>
      </a>
    </li>
  );
};

export default NavBarMobileLink;
