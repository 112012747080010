import ReactMarkdown from 'react-markdown';
import Link from './Link';

const Markdown = ({ children }) => (
  <div className="ParagraphItem css-huenw5 ql-align-center">
    <ReactMarkdown
      components={{
        a(props) {
          const { node, href, ...otherProps } = props;
          return (
            <Link
              {...otherProps}
              href={href}
              target={href.startsWith('http') ? '_blank' : '_self'}
              className="markdown-anchor"
            />
          );
        }
      }}
    >
      {children}
    </ReactMarkdown>
  </div>
);

export default Markdown;
