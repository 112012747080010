import { places } from 'assets/js/data';

const mapsSearchUrlRoot = `https://www.google.com/maps/search/`;

export const getMapsSearchUrl = locationId => {
  const { placeId, lat, lng } = places[locationId];
  const mapsUrlParamsObj = {
    api: 1,
    query: `${lat},${lng}`,
    query_place_id: placeId
  };
  const mapsUrlSearchParams = new URLSearchParams(mapsUrlParamsObj);
  return `${mapsSearchUrlRoot}?${mapsUrlSearchParams.toString()}`;
};

export const navigateToMapsSearchUrl = (locationId) => {
  window.open(getMapsSearchUrl(locationId), '_blank');
};
