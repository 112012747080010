const defaultTheme = {
  fontFamily: 'Helvetica, sans-serif',
  gradientStart: '#0181C2',
  gradientMid: '#04A7F9',
  gradientEnd: '#4BC4F7',
  locationFontColor: '#FFF',
  todayTempFontColor: '#FFF',
  todayDateFontColor: '#B5DEF4',
  todayRangeFontColor: '#B5DEF4',
  todayDescFontColor: '#B5DEF4',
  todayInfoFontColor: '#B5DEF4',
  todayIconColor: '#FFF',
  forecastBackgroundColor: '#FFF',
  forecastSeparatorColor: '#DDD',
  forecastDateColor: '#777',
  forecastDescColor: '#777',
  forecastRangeColor: '#777',
  forecastIconColor: '#4BC4F7',
  containerDropShadow: '0px 3px 6px 0px rgba(50, 50, 50, 0.5)',
};

export default defaultTheme;
