import RouteWrapper from 'routes/RouteWrapper';
import { GoogleMapsLink, Image, Markdown } from 'common';
import { locationIds, preWedding } from 'assets/js/data';
import preweddingImages from 'assets/img/preweddingImages';
import { ImgResIndex } from 'utils/image';

const PreWedding = () => (
  <RouteWrapper>
    <div className="css-iex2id">
      <Image
        highResSrc={preweddingImages[ImgResIndex.LOW]}
        mediumResSrc={preweddingImages[ImgResIndex.MEDIUM]}
        lowResSrc={preweddingImages[ImgResIndex.LOW]}
      />
      <Markdown>{preWedding.textStart}</Markdown>
      <Markdown>{preWedding.textTour}</Markdown>
      <GoogleMapsLink locationId={locationIds.TOUR_MEETING_POINT} text="Punto de encuentro (18.30)" />
      <Markdown>{preWedding.textPreWedding}</Markdown>
      <GoogleMapsLink locationId={locationIds.PREWEDDING} text="Preboda (20.00)" />
      <Markdown>{preWedding.textEnd}</Markdown>
    </div>
  </RouteWrapper>
);

export default PreWedding;
