import { wedding } from 'assets/js/data'

const FooterMain = () => (
  <div className="css-10xb0p9">
    <div className="css-fpza88">{wedding.coupleInitials}</div>
    <div className="css-b4drd9">{wedding.date}</div>
  </div>
);

export default FooterMain;
