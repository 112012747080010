import wedding1020 from 'assets/img/wedding_1020.png';
import wedding765 from 'assets/img/wedding_765.png';
import wedding510 from 'assets/img/wedding_510.png';
import { ImgResIndex } from 'utils/image';

const weddingImages = {
  [ImgResIndex.HIGH]: wedding1020,
  [ImgResIndex.MEDIUM]: wedding765,
  [ImgResIndex.LOW]: wedding510
};

export default weddingImages;
