import BottomNavBarMobile from './BottomNavBarMobile';
import Copyright from './Copyright';
import FooterMain from './FooterMain';

const Footer = () => (
  <footer className="css-rzmzie">
    <BottomNavBarMobile />
    <FooterMain />
    <Copyright />
  </footer>
);

export default Footer;
