import honeymoon1020 from 'assets/img/honeymoon_1020.png';
import honeymoon765 from 'assets/img/honeymoon_765.png';
import honeymoon510 from 'assets/img/honeymoon_510.png';
import { ImgResIndex } from 'utils/image';

const honeymoonImages = {
  [ImgResIndex.HIGH]: honeymoon1020,
  [ImgResIndex.MEDIUM]: honeymoon765,
  [ImgResIndex.LOW]: honeymoon510
};

export default honeymoonImages;
