import { useEffect, useContext } from 'react';
import { useOutletContext } from 'react-router-dom';
import { WindowDimensionsContext } from 'context/windowDimensions';

const RouteWrapper = ({ children }) => {
  const target = useOutletContext();
  const { width } = useContext(WindowDimensionsContext);
  const isMobile = width < 768;

  useEffect(() => {
    if (isMobile) {
      window.scrollTo({
        top: target.current.offsetTop
      });
    }
  }, [target, isMobile]);

  return <>{children}</>;
};

export default RouteWrapper;
