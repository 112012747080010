import { useState } from 'react';
import { gift } from 'assets/js/data';

const BankAccount = () => {
  const [accountCopied, setAccountCopied] = useState(false);

  const copyAccountToClipboard = () => {
    navigator.clipboard.writeText(gift.iban);
    setAccountCopied(true);
  };

  return (
    <div className="clipboard-field-container">
      <div className="clipboard-field">
        <span className="clipboard-field-text">{gift.iban}</span>
        <button
          className={`clipboard-field-button ${accountCopied ? 'copied' : ''}`}
          onClick={copyAccountToClipboard}
        >
          {accountCopied ? 'Copiado' : 'Copiar'}
        </button>
      </div>
    </div>
  );
};

export default BankAccount;
