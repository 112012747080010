import { getPhoneCallUrl, getMailUrl } from 'utils/contact';
import Paragraph from 'common/Paragraph';
import { Fragment } from 'react';

const ContactInfo = ({ contacts }) => (
  <Paragraph className="contact">
    {contacts.map(({ id, name, phone, email }) => (
      <Fragment key={id}>
        <a href={getPhoneCallUrl(phone)}>
          {name} | {phone}
        </a>
        {email && (
          <>
            | <a href={getMailUrl(email)}>{email}</a>
          </>
        )}
        <br />
      </Fragment>
    ))}
  </Paragraph>
);

export default ContactInfo;
