import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Link from 'common/Link';

const Copyright = () => (
  <div className="css-1yrcy90">
    Desarrollado por Juan Palomo
    <br />
    <div>
      <Link href="https://linkedin.com/in/fernando-alegria">
        <FontAwesomeIcon icon={['fab', 'linkedin']} />
      </Link>
      <Link href="https://github.com/fernanalegria">
        <FontAwesomeIcon icon={['fab', 'github']} />
      </Link>
    </div>
  </div>
);

export default Copyright;
