import { useEffect, useState } from 'react';
import { getTimeToWedding } from 'utils/datetime';

const CountdownTimer = () => {
  const [timeToWedding, setTimeToWedding] = useState(getTimeToWedding());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeToWedding(getTimeToWedding());
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <div className="css-mx8zre">
      <div className="countdown-timer-container">
        <div className="countdown-timer-col">
          <span className="countdown-timer-txt1 p-b-4">
            {timeToWedding.days}
          </span>
          <span className="countdown-timer-txt2">Días</span>
        </div>
        <span className="countdown-timer-txt1 p-b-40">:</span>
        <div className="countdown-timer-col">
          <span className="countdown-timer-txt1 p-b-4">
            {timeToWedding.hours}
          </span>
          <span className="countdown-timer-txt2">Horas</span>
        </div>
        <span className="countdown-timer-txt1 p-b-40">:</span>
        <div className="countdown-timer-col">
          <span className="countdown-timer-txt1 p-b-4">
            {timeToWedding.minutes}
          </span>
          <span className="countdown-timer-txt2">Minutos</span>
        </div>
        <span className="countdown-timer-txt1 p-b-40">:</span>
        <div className="countdown-timer-col">
          <span className="countdown-timer-txt1 p-b-4">
            {timeToWedding.seconds}
          </span>
          <span className="countdown-timer-txt2">Segundos</span>
        </div>
      </div>
    </div>
  );
};

export default CountdownTimer;
