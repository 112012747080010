import { wedding } from 'assets/js/data';

const HeaderMobile = () => (
  <header className="css-mj00yc">
    <h1
      data-testid="wedding-couple-names"
      className="css-1bxc5m3"
    >
      {wedding.coupleNames}
    </h1>
    <div
      data-testid="wedding-wedding-date"
      className="css-149qu95"
    >
      {wedding.dateWritten}
    </div>
    <div
      data-testid="wedding-wedding-location"
      className="css-149qu96"
    >
      {wedding.location}
    </div>
  </header>
);

export default HeaderMobile;
