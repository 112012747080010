import { NavLink } from 'react-router-dom';

const NavBarDesktopLink = ({ to, name, root = '/' }) => (
  <li className="css-haue6f">
    <NavLink to={`${root}${to}`} className="css-1x9swe2">
      {({ isActive }) => (
        <span className={isActive ? 'css-1b16ice' : 'css-kheh8y'}>{name}</span>
      )}
    </NavLink>
  </li>
);

export default NavBarDesktopLink;
