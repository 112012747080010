import NavBarDesktopLink from './NavBarDesktopLink';

const linksPerNavRow = 4;

const NavBarDesktop = ({ pages, className = '', root }) => {
  const navRows = Math.ceil(pages.length / linksPerNavRow);
  return (
    <nav className={`css-o8h4rm ${className}`}>
      <div className="css-1bpqnod">
        {[...Array(navRows).keys()].map(navRowId => {
          const startIndex = linksPerNavRow * navRowId;
          const isLastNavRow = navRowId === navRows - 1;
          const endIndex = isLastNavRow
            ? pages.length
            : startIndex + linksPerNavRow;
          return (
            <ul
              key={navRowId}
              data-testid={`desktop-nav-row-${navRowId}`}
              className="css-16kcklx"
            >
              {pages.slice(startIndex, endIndex).map(({ id, to, name }) => (
                <NavBarDesktopLink key={id} to={to} name={name} root={root} />
              ))}
            </ul>
          );
        })}
      </div>
    </nav>
  );
};

export default NavBarDesktop;
