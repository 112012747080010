import flowers3000 from 'assets/img/desktop/texture_3000.png';
import flowers2560 from 'assets/img/desktop/texture_2560.png';
import flowers2250 from 'assets/img/desktop/texture_2250.png';
import flowers2048 from 'assets/img/desktop/texture_2048.png';
import flowers1920 from 'assets/img/desktop/texture_1920.png';
import flowers1536 from 'assets/img/desktop/texture_1536.png';
import flowers1500 from 'assets/img/desktop/texture_1500.png';
import flowers1280 from 'assets/img/desktop/texture_1280.png';
import flowers1024 from 'assets/img/desktop/texture_1024.png';

const TopImageDesktop = () => (
  <picture className="css-as6t7l">
    <source
      media="(min-resolution: 192dpi) and (min-width: 1280px),(-webkit-min-device-pixel-ratio: 2) and (min-width: 1280px)"
      srcSet={flowers3000}
    />
    <source
      media="(min-resolution: 192dpi) and (min-width: 1024px),(-webkit-min-device-pixel-ratio: 2) and (min-width: 1024px)"
      srcSet={flowers2560}
    />
    <source
      media="(min-resolution: 192dpi) and (min-width: 768px),(-webkit-min-device-pixel-ratio: 2) and (min-width: 768px)"
      srcSet={flowers2048}
    />
    <source
      media="(min-resolution: 144dpi) and (min-width: 1280px),(-webkit-min-device-pixel-ratio: 1.5) and (min-width: 1280px)"
      srcSet={flowers2250}
    />
    <source
      media="(min-resolution: 144dpi) and (min-width: 1024px),(-webkit-min-device-pixel-ratio: 1.5) and (min-width: 1024px)"
      srcSet={flowers1920}
    />
    <source
      media="(min-resolution: 144dpi) and (min-width: 768px),(-webkit-min-device-pixel-ratio: 1.5) and (min-width: 768px)"
      srcSet={flowers1536}
    />
    <source media="(min-width: 1280px)" srcSet={flowers1500} />
    <source media="(min-width: 1024px)" srcSet={flowers1280} />
    <source media="(min-width: 768px)" srcSet={flowers1024} />
    <img loading="eager" alt="" src={flowers1024} className="css-1ago99h" />
  </picture>
);

export default TopImageDesktop;
