import { locationIds, places, weather } from 'assets/js/data';
import RouteWrapper from 'routes/RouteWrapper';
import OpenWeatherWrapper from './OpenWeatherWrapper';
import { Markdown } from 'common';

const weatherLocations = [
  {
    ...places[locationIds.TOUR_MEETING_POINT],
    locationLabel: 'Burgos'
  },
  {
    ...places[locationIds.WEDDING],
    locationLabel: 'Monasterio del Espino'
  }
];

const Weather = () => {
  return (
    <RouteWrapper>
      <div className="css-iex2id">
      <Markdown>{weather.warning}</Markdown>
      <Markdown>{weather.intro}</Markdown>
        {weatherLocations.map(location => (
          <OpenWeatherWrapper {...location} />
        ))}
      </div>
    </RouteWrapper>
  );
};

export default Weather;
