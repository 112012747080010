import { createContext, useReducer } from 'react';
import { pages } from 'assets/js/data';
import reducer from './reducer';

const routes = pages.map(({ to }) => to);
const currentRoute = window.location.pathname.substring(1);

const initialState = {
  activePage: routes.includes(currentRoute) ? currentRoute : 'home'
};

export const ActivePageContext = createContext();

export const ActivePageProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <ActivePageContext.Provider value={[state, dispatch]}>
      {children}
    </ActivePageContext.Provider>
  );
};
