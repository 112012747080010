import ImageHome from './ImageHome';
import TextHome from './TextHome';
import CountdownTimer from './CountdownTimer';
import RouteWrapper from 'routes/RouteWrapper';

const Home = () => (
  <RouteWrapper>
    <div className="css-iex2id">
      <ImageHome />
      <TextHome />
      <CountdownTimer />
    </div>
  </RouteWrapper>
);

export default Home;
